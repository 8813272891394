import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/uCyF6tbZyKI">
    <SEO title="The Problem of Pragmatism - Jesus in Genesis" />
  </Layout>
)

export default SermonPost
